<template>
  <v-container v-if="vueOrigens">
    <PageToolbar
      :title="$tc('pages.origem', 2)"
      icon="person_pin_circle"
      dark
    />

    <ListaOrigens class="mt-6" :key="componentKey" />

    <v-dialog v-model="dialog" max-width="290">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1"
          >{{ $tc("global.nova") }} {{ $tc("pages.origem") }}</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="origen.descricao"
            :label="$tc('global.descricao')"
            dense
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="anchors" text @click="dialog = false">{{
            $tc("botoes.cancelar")
          }}</v-btn>
          <v-btn color="anchors" text @click="createOrigen">{{
            $tc("botoes.criar")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn
      v-if="vueAddOrigen"
      @click="dialog = true"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import OrigensApi from "@/services/api/origens.api.js";

import { mapGetters } from "vuex";

export default {
  name: "Origens",
  components: {
    ListaOrigens: () => import("./components/ListaOrigens.vue"),
  },
  data: () => ({
    componentKey: 0,
    origen: {},
    dialog: false,
    loading: false,
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueOrigens() {
      return this.getAccess("vueOrigens", "menu");
    },
    vueAddOrigen() {
      return this.getAccess("vueOrigen", "adicionar");
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    createOrigen() {
      this.loading = true;
      OrigensApi.postOrigens(this.origen.descricao)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.criadosucesso"));
            this.origen = {};
            this.forceRerender();
            this.dialog = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style></style>
